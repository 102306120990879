<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      :labelWidth="labelWidth"
      :editTitle="'供应商'"
    >
      <div class="search" slot="tool">
        <el-input
          style="width: 280px"
          clearable
          placeholder="请输入供应商姓名"
          v-model="searchQuery.supplierName"
          class="searchInput"
        >
          <template slot="prepend">供应商姓名</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/safetyTrace/supplier",

      searchVal: "",
      jobList: [],
      searchQuery: { name: "" },
      labelWidth: "120px",
      rules: {
        supplierName: [
          //供应商
          { required: true, message: "请输入供应商", trigger: ["blur","change"] },
        ],
        supplierContactPeople: [
          //联系人
          { required: true, message: "请输入联系人", trigger: ["blur","change"] },
        ],
        supplierContactPhone: [
          //联系电话
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("手机号不能为空"));
              } else {
                if (!this.$test.mobile(value)) {
                  callback(new Error("手机号格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: true,
            trigger: ["blur","change"],
          },
        ],
        belongArea: [
          { required: true, message: "请输入所属区域", trigger: ["blur","change"] },
        ],
        licenseNumber: [
          { required: true, message: "请输入证照号码", trigger: ["blur","change"] },
        ],
        evidenceImageOne: [
          { required: true, message: "请上传证明材料1", trigger: ["blur","change"] },
        ],
        evidenceImageTwo: [
          { required: true, message: "请上传证明材料2", trigger: ["blur","change"] },
        ],
      },
      props: [
        {
          label: "供应商名称",
          prop: "supplierName",
          align: "center",
        },
        {
          label: "联系人",
          prop: "supplierContactPeople",
          align: "center",
        },
        {
          label: "联系电话",
          prop: "supplierContactPhone",
          align: "center",
        },
        {
          label: "所属区域",
          prop: "belongArea",
          align: "center",
        },
        {
          label: "证照号码",
          prop: "licenseNumber",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "供应商",
          prop: "supplierName",
          type: "input",
        },
        {
          label: "联系人",
          prop: "supplierContactPeople",
          type: "input",
        },
        {
          label: "联系电话",
          prop: "supplierContactPhone",
          type: "input",
        },
        {
          label: "所属区域",
          prop: "belongArea",
          type: "input",
        },
        {
          label: "证照号码",
          prop: "licenseNumber",
          type: "input",
          isFull: true,
        },

        {
          label: "证明材料图片1",
          prop: "evidenceImageOne",
          type: "upload",
        },
        {
          label: "证明材料图片2",
          prop: "evidenceImageTwo",
          type: "upload",
        },
      ],
    };
  },
  created() {},
  methods: {
    // async getTypeList() {
    //   await api.common.getBusinessTypeList().then((res) => {
    //     let arr = res.data;
    //     arr.forEach((item) => {
    //       this.formProps[3].selectData.push({ value: item.id, label: item.id });
    //     });
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
